import "./utils/initialize_highcharts"
import { applePost, facebookSSO } from "shared/sso"
import AdminLayover from "app/scripts/admin_layover"
import DynamicConfigurationToggle from "app/scripts/dynamic_configuration_toogle"
import HandleVerifyDeleteAccountByEmail from "app/scripts/handle_verify_delete_account_by_email"
import InitializeFacebook from "./scripts/initialize_facebook"
import PushNotification from "app/scripts/push_notification"
import ServiceTabs from "app/scripts/service_tabs"
import { setDatadogRumContext } from "app/scripts/shared_setup"
import SetupActivityFeed from "app/scripts/setup_activity_feed"
import SetupDeleteAccountSurvey from "app/scripts/setup_delete_account_survey"
import SetupUnifiedHeaderDropdown from "shared/scripts/setup_unified_header_dropdown"
import SetupUnifiedMobileMenu from "shared/scripts/setup_unified_mobile_menu"
import Success from "app/components/subscriptions/success"
import TFASendPin from "app/scripts/tfa_send_pin"
import UpdateParam from "app/scripts/update_param"
import modalHandler from "shared/scripts/modal"
import shareButton from "shared/scripts/share_button"

import handleRecaptcha from "shared/handle_recaptcha"
import AlreadySignedInPage from "app/components/sdk/already_signed_in_page"
import ConfigurePage from "app/components/sdk/configure_page"
import ConnectedServicePage from "app/components/sdk/connected_service_page"
import LandingPage from "app/components/sdk/landing_page"
import RefreshFormAuthenticityToken from "app/scripts/refresh_form_authenticity_token"
import ResetPasswordPage from "app/components/sdk/reset_password_page"
import SignInPage from "app/components/sdk/sign_in_page"
import SignUpPage from "app/components/sdk/sign_up_page"

import ActivateServiceWithPin from "app/components/activate_service/activate_service_with_pin"
import ActivateServiceWithToken from "app/components/activate_service/activate_service_with_token"
import ActivateWordpress from "app/components/activate_service/activate_wordpress"

// Generic Helper components
import PasswordUnmasking from "shared/scripts/password_un_masking"
import LazyBGImageObserver from "app/scripts/lazy_bg_img_observer"

// Page Specific Components
import ActivitySteps from "app/components/activity_steps"
import SLAPrompt from "shared/components/sla_prompt"

// Stored Fields
import IngredientTextField from "shared/components/stored_fields/ingredient_text_field/index"

import ConnectSDKAppletConfig from "app/components/connect_sdk_applet/config"
import ConnectionCard from "shared/components/connection_card"
import ConnectionConfig from "shared/components/connection_card/config"
import ConnectButton from "shared/components/connection_card/connect_button"
import ConnectionCardTitle from "shared/components/connection_card/title"
import AppletFeedback from "app/components/platform_feedback/applet_feedback"
import ReturningUserFlow from "app/components/connect_sdk_applet/returning_user_flow"
import TransitionAndRedirect from "app/components/connect_sdk_applet/returning_user_flow/transition_and_redirect"

import MyServiceSettingsView from "app/components/ifttt_next/services/my_service_settings_view/index"
import Profile from "app/components/ifttt_next/profile/index"
import GatedServiceAuthButton from "app/components/gated_service_auth_button"
import LearnMoreButton from "shared/components/learn_more_button"
import ShareableAppletCard from "shared/components/applet_card/ifttt_next/shareable_applet_card"

import BillingSwitchPlanLink from "app/components/billing/switch_plan/index.tsx"
import BillingCancelContainer from "app/components/billing/cancel/index.tsx"
import PromotionContainer from "app/components/billing/cancel/promotion/index.tsx"
import ReferralCode from "app/components/referral/index.tsx"
import Rewards from "app/components/referral/rewards/index.tsx"
import ReceiptSettings from "app/components/billing/receipt_settings"

import FeedbackPrompt from "app/components/feedback_prompt/index.tsx"
import ToggleHelpWidgetVisibility from "shared/scripts/toggle_help_widget_visibility"

import ProsumerHome from "app/components/prosumer_home"
import PlanUpgradeButton from "app/components/plan_upgrade_button"

import { loadChunk, setAppReady } from "shared/lib/utils"

Object.assign(window.App.Comps, {
  // React components
  ActivateServiceWithPin,
  ActivateServiceWithToken,
  ActivateWordpress,
  ActivitySteps,
  AppletFeedback,
  BillingCancelContainer,
  PromotionContainer,
  ReferralCode,
  Rewards,
  BillingSwitchPlanLink,
  FeedbackPrompt,
  GatedServiceAuthButton,
  IngredientTextField,
  LearnMoreButton,
  MyServiceSettingsView,
  Profile,
  ReceiptSettings,
  SLAPrompt,
  ShareableAppletCard,
  PlanUpgradeButton,
  Subscriptions: {
    Success,
  },
  Sdk: {
    AlreadySignedInPage,
    ConfigurePage,
    ConnectedServicePage,
    LandingPage,
    ResetPasswordPage,
    SignInPage,
    SignUpPage,
  },
  Access: {
    ConnectSDKAppletConfig,
    ReturningUserFlow,
    TransitionAndRedirect,
  },
  ConnectionCard: {
    ConnectionCard,
    ConnectionConfig,
    ConnectButton,
    Title: ConnectionCardTitle,
  },
  ConnectURL: {},
})

// Per-page functions to setup non-react behavior
Object.assign(window.App.Scripts, {
  AdminLayover,
  applePost,
  DynamicConfigurationToggle,
  facebookSSO,
  handleRecaptcha,
  HandleVerifyDeleteAccountByEmail,
  InitializeFacebook,
  LazyBGImageObserver,
  modalHandler,
  PasswordUnmasking,
  ProsumerHome,
  PushNotification,
  RefreshFormAuthenticityToken,
  ServiceTabs,
  SetDatadogRumContext: setDatadogRumContext,
  SetupActivityFeed,
  SetupDeleteAccountSurvey,
  SetupUnifiedHeaderDropdown,
  SetupUnifiedMobileMenu,
  shareButton,
  TFASendPin,
  ToggleHelpWidgetVisibility,
  UpdateParam,
})

const controllerChunkMap = {
  subscriptions: {
    importFn: () =>
      import(/* webpackChunkName: "wp-subscriptions" */ "app/components/subscriptions/sign_up_and_purchase"),
    component: "SignUpAndPurchase",
    location: ["Comps", "Subscriptions"],
  },
  billing: {
    importFn: () =>
      import(/* webpackChunkName: "wp-billing" */ "app/components/subscriptions/update_payment_info/index"),
    component: "UpdatePaymentInfo",
    location: ["Comps", "Subscriptions"],
  },
  url: {
    importFn: () => import(/* webpackChunkName: "wp-url" */ "app/components/connect_url"),
    component: "Container",
    location: ["Comps", "ConnectURL"],
  },
  diy_creation: {
    importFn: () => import(/* webpackChunkName: "wp-diy_creation" */ "app/components/diy_composer"),
    component: "DIY",
    location: ["Comps"],
  },
}

let chunkName
if (App.controllerName === "connections" && App.actionName === "edit") {
  chunkName = "diy_creation"
} else {
  chunkName = App.controllerName
}

if (controllerChunkMap[chunkName]) {
  await loadChunk(controllerChunkMap[chunkName])
}
setAppReady()

window.addEventListener("load", () => {
  Object.keys(controllerChunkMap)
    .filter(c => c !== chunkName)
    .forEach(controllerName => {
      loadChunk(controllerChunkMap[controllerName])
    })
})
